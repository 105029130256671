import React, {useEffect} from 'react';
import {observer, useLocalObservable} from "mobx-react-lite";
import {inject} from "mobx-react";
import {useParams, useNavigate} from "react-router-dom";
import {useSpring, animated} from "@react-spring/web";
import Timer from "../components/timer";
import PrimaryButton from "../components/PrimaryButton";
import axios from "axios";

let couponTimer;

function Checkout({DeviceStore}){

  const {organisation_slug, shop_id} = useParams();
  const navigate = useNavigate();

  const state = useLocalObservable(() => ({
    loading: false,
    hasCoupon: false,
    couponCode: '',
    coupon: null,
    method: '',
    agreed: false,
    whatsApp: false,
    idealUptime: null,
  }));

  const idealUptime = async () => {
    if(state.idealUptime !== null){
      return;
    }
    try {
      state.idealUptime = (await axios.get('https://api.mijnetickets.nl/ideal-uptime')).data;
    } catch (e) {
      //
    }
  }

  const springs = useSpring({
    from: { x: 25, opacity: 0, },
    to: { x: 0, opacity: 1 },
  })

  useEffect(() => {
    if(!DeviceStore.reservation){
      navigate(`/${organisation_slug}/${shop_id}`, {replace: true})
      return
    }
    DeviceStore.setStep(3);
    document.title = t('place_order') +' - ' + DeviceStore.eventData.event.name;

    if(DeviceStore.eventData.pay_methods.length === 1){
      setMethod({target: {value: DeviceStore.eventData.pay_methods[0].name}})
    }

  }, []);

  if(!DeviceStore.eventData){
    return null;
  }

  const {event, coupons, privacy_url, conditions_url, organisation, sms, sms_fee, documents } = DeviceStore.eventData;

  let {pay_methods} = DeviceStore.eventData;

  if(!window.ApplePaySession){
    pay_methods = pay_methods.filter(method => method.name !== '2277')
  }

  const tickets = DeviceStore.getTicketTypes;

  let {seatplan} = DeviceStore.eventData;

  const {theme, t, selectedTickets, wantsNewsletter, wantsWhatsApp, defaultLocale, entranceShop} = DeviceStore;

  const goBack = () => {
    navigate(`/${organisation_slug}/${shop_id}/registration`);
    if(DeviceStore.iframe) {
      window.scrollTo(0, 0);
      window.parent.postMessage({
        type: 'scrollTop',
      }, "*");
    }
  }

  const setMethod = ({target}) => {
    state.method = target.value;
    if(target.value === '10'){
      idealUptime();
    }
  }

  const setAgreement = ({target}) => {
    state.agreed = target.checked;
  }

  const setHasCoupon = ({target}) => {
    state.hasCoupon = target.checked;
    if(!state.hasCoupon){
      state.coupon = null;
      state.couponCode = '';
    }
  }

  const next = async (e) => {
    e.preventDefault();
    if (state.loading) return;
    state.loading = true;
    sendAnalyticsCheckout();
    const result = await DeviceStore.createOrder(state.method, state.couponCode);
    if(!result){
      navigate(`/${organisation_slug}/${shop_id}`)
      return;
    }
    navigate(`/${organisation_slug}/${shop_id}/redirect`)
    state.loading = false;
  }

  const sendAnalyticsCheckout = () => {

    if(!window.gtag){
      return;
    }

    let items = [];

    try {

      selectedTickets.forEach(ticket => {
        const type = tickets.find(t => t.id === ticket.tickettype_id)
        let price = type.price;
        if (price) {
          price += event.customer_ticket_fee
        }
        items.push({
          item_id: type.id,
          item_name: type.name[defaultLocale],
          price: price,
          quantity: 1
        })
      })

      const tagData = {
        currency: "EUR",
        value: total(),
        items: items,
      };

      window.gtag("event", "begin_checkout", tagData);
    } catch (e) {
      //
    }
  }

  const setPhoneNumber = ({target}) => {
    DeviceStore.setFormValue('telephone', target.value);
  }

  const checkCouponCode = async () => {
    state.coupon = await DeviceStore.checkCouponCode(state.couponCode);
  }

  const couponInput = ({target}) => {
    state.couponCode = target.value;
    clearTimeout(couponTimer);
    if(state.couponCode) {
      couponTimer = setTimeout(checkCouponCode, 500);
    } else {
      state.coupon = null;
    }
  }

  const total = (coupon = true, transactionCosts = true) => {
    let total = 0;
    let fees = 0;
    selectedTickets.forEach(ticket => {
      const type = tickets.find(t => t.id === ticket.tickettype_id)
      let price = type.price;
      if(price){
        price += event.customer_ticket_fee;
        fees += event.customer_ticket_fee;
      }
      total += price;
    })

    if(wantsWhatsApp){
      total += sms_fee;
    }

    if(DeviceStore.donation){
      total += parseFloat(DeviceStore.donation);
    }

    let discount = 0;

    if(coupon && state.coupon){
      discount = state.coupon.discount;
      if(total - fees <= discount){
        discount = total;
      }
    }

    total -= discount;

    if(total && event.customer_transaction_fee && state.method && transactionCosts){
      const {costs} = pay_methods.find(m => m.name === state.method);
      total += (costs.variable / 100 * total) * 1.21;
      total += costs.fixed * 1.21;
    }

    return total;
  }

  const renderSelectedTickets = () => {
    let data = {};
    selectedTickets.forEach(ticket => {
      const key = ticket.tickettype_id + (ticket.variant_id ? '_v'+ticket.variant_id : '') + (ticket.seat_id ? '_s'+ticket.seat_id : '');
      const type = tickets.find(t => t.id === ticket.tickettype_id);
      const variant = type.variants.find(v => v.id === ticket.variant_id);
      let seat = null;
      if(ticket.seat_id){
        const seats = DeviceStore.date ? seatplan.seats.find(s => s.date_id === DeviceStore.date).seats : seatplan.seats;
        seat = seats.find(s => s.id === ticket.seat_id);
      }
      let price = type.price;
      if(price){
        price += event.customer_ticket_fee
      }
      if(!data[key]){
        data[key] = {
          key: key,
          ticket_name: type.name[defaultLocale],
          variant_name: variant ? variant.name : null,
          seat_name: seat ? seat.name : null,
          price: price,
          amount: 0
        }
      }
      data[key].amount++;
    })

    return Object.values(data).map((ticket) => {
      const priceLabel = new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(ticket.price);
      const total = new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(ticket.price * ticket.amount)
      return (<tr className="border-b dark:border-gray-700" key={ticket.key}>
        <td className=" py-4 pl-4 pr-3 text-sm font-medium text-gray-900 dark:text-gray-200 sm:pl-6">{ticket.ticket_name} {ticket.seat_name ?? ticket.variant_name ? <small>({ticket.seat_name}{ticket.variant_name})</small> : null }</td>
        <td className=" px-3 py-4 text-sm text-gray-500 text-right dark:text-gray-300">{priceLabel}</td>
        <td className=" px-3 py-4 text-sm text-gray-500 text-right dark:text-gray-300">{ticket.amount}</td>
        <td className=" px-3 py-4 text-sm text-gray-500 text-right dark:text-gray-300">{total}</td>
      </tr>)
    })
  }

  let documentsMap = [
    {
      url: conditions_url,
      name: t('terms'),
    },
    {
      url: privacy_url,
      name: t('privacy'),
    },
    ...documents,
  ];

  return (
      <animated.div style={springs}>
        <form onSubmit={next}>
          <div className="bg-white rounded-lg overflow-hidden border border-gray-200 shadow-md dark:bg-gray-900 dark:border-gray-700">
            <table className="min-w-full divide-y divide-gray-300 dark:divide-gray-700">
              <thead className="bg-gray-50 dark:bg-gray-900">
              <tr>
                <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 dark:text-gray-100">Ticket</th>
                <th className="py-3.5 pl-4 pr-3 text-sm font-semibold text-gray-900 sm:pl-6 text-right dark:text-gray-100">{t('price')}</th>
                <th className="py-3.5 pl-4 pr-3 text-sm font-semibold text-gray-900 sm:pl-6 text-right dark:text-gray-100">{t('amount')}</th>
                <th className="py-3.5 pl-4 pr-3 text-sm font-semibold text-gray-900 sm:pl-6 text-right dark:text-gray-100">{t('total')}</th>
              </tr>
              </thead>
              <tbody>
              {renderSelectedTickets()}
              {DeviceStore.donation ? <tr>
                <td className=" py-4 pl-4 pr-3 text-sm font-medium text-gray-900 dark:text-gray-100 sm:pl-6">{t('donation')}</td>
                <td className=" px-3 py-4 text-sm text-gray-500 text-right dark:text-gray-100">{new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(DeviceStore.donation)}</td>
                <td className=" px-3 py-4 text-sm text-gray-500 text-right dark:text-gray-100">1</td>
                <td className=" px-3 py-4 text-sm text-gray-500 text-right dark:text-gray-100">{new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(DeviceStore.donation)}</td>
              </tr> : null }
              </tbody>
            </table>

          </div>
          <div className="bg-white rounded-lg overflow-hidden border border-gray-200 shadow-md dark:bg-gray-900 dark:border-gray-700 mt-4">
            <div className="px-4 sm:px-5 p-5 space-y-4">

              {sms && !entranceShop ? <div className="relative flex items-start mb-4">
                <div className="flex items-center h-5">
                  <input id="whatsapp" checked={wantsWhatsApp} onChange={DeviceStore.setWhatsApp} type="checkbox" className="h-4 w-4 text-blue-600 border-gray-200 dark:border-gray-700 rounded focus:ring-0 focus:ring-offset-0" style={{color: theme.primary_color}}/>
                </div>
                <div className="ml-3 text-sm">
                  <label htmlFor="whatsapp" className="font-medium text-gray-700 dark:text-gray-300">{t('send_whatsapp')}{sms_fee ? ' ('+new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(sms_fee)+')' : ''}.</label>
                </div>
              </div> : null}

              {sms && wantsWhatsApp ? <div  className="relative flex items-start mb-4">
                <input
                    type="tel"
                    minLength={10}
                    pattern="(^\+[0-9]{2}|^\+[0-9]{2}\(0\)|^\(\+[0-9]{2}\)\(0\)|^00[0-9]{2}|^0)([0-9]{9}$|[0-9\-\s]{10}$)"
                    required
                    value={DeviceStore.formData.telephone}
                    onChange={setPhoneNumber}
                    name="mobile_number"
                    placeholder="0612345678"
                    className="max-w-lg block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md  dark:border-gray-700 dark:bg-gray-950 dark:text-white"
                />
              </div> : null}

              {!entranceShop ? <div id="newsletter_checkbox" className="relative flex items-start mb-4">
                <div className="flex items-center h-5">
                  <input id="newsletter" checked={wantsNewsletter} onChange={DeviceStore.setNewsletter} type="checkbox" className="h-4 w-4 text-blue-600 border-gray-200 rounded focus:ring-0 focus:ring-offset-0" style={{color: theme.primary_color}}/>
                </div>
                <div className="ml-3 text-sm">
                  <label htmlFor="newsletter" className="font-medium text-gray-700 dark:text-gray-300">{t('newsletter', [organisation.name])}</label>
                </div>
              </div> : null}

              {total(false) && coupons ? <div className="mb-4">

                <div className="mb-4">
                  <div className="relative flex items-start">
                    <div className="flex items-center h-5">
                      <input id="couponcheck" checked={state.hasCoupon} onChange={setHasCoupon} type="checkbox" className="h-4 w-4 text-blue-600 border-gray-200 rounded focus:ring-0 focus:ring-offset-0" style={{color: theme.primary_color}}/>
                    </div>
                    <div className="ml-3 text-sm">
                      <label htmlFor="couponcheck" className="font-medium text-gray-700 dark:text-gray-300">{t('got_voucher')}</label>
                    </div>
                  </div>

                  { state.hasCoupon ? <div className="sm:col-span-3 mt-1">
                    <div className="mt-1">
                      <input type="text" value={state.couponCode} placeholder={t('discount_code')} onChange={couponInput} className="max-w-lg block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm rounded-md border-gray-300 dark:border-gray-700 dark:bg-gray-950 dark:text-white"/>
                    </div>
                    {state.coupon === false ? <div className="text-xs text-red-600">Ongeldige kortingscode</div> : null}
                    {state.coupon ? <div className="text-xs text-green-600">Geldige kortingscode!</div> : null}
                  </div> : null }

                </div>

              </div> : null}

              <div className="relative flex items-center">
                <div className="flex items-center h-5">
                  <input id="agreement" required checked={state.agreed} onChange={setAgreement} type="checkbox" className="h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-0 focus:ring-offset-0" style={{color: theme.primary_color}}/>
                </div>
                <div className="ml-3 text-sm">
                  <label htmlFor="agreement" className="font-medium text-gray-700 dark:text-gray-300">{t('i_agree_with') + ' '}
                    { documentsMap.map((document, index) => <span key={document.name}><a className="text-blue-600 underline" target="_blank" rel="noreferrer" href={document.url}>{document.name}</a>{index < documentsMap.length - 2  ? ', ' :  ( documentsMap.length - 2 === index ? (' ' + t('and') + ' ') : '' ) }</span>) }
                    .</label>
                </div>
              </div>

            </div>
          </div>
          <div className="bg-white rounded-lg overflow-hidden border border-gray-200 shadow-md dark:bg-gray-900 dark:border-gray-700 mt-4">
            <div className="px-4 sm:px-5 p-5 space-y-4 md:flex md:justify-between items-end md:pr-6 dark:border-gray-700">
              {total() ? <div className="space-y-4 w-full max-w-2xl">
                <div className="">

                  <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100">
                    {t('pay_method')}
                  </h3>

                  <div className="gap-2 mt-3 grid sm:grid-cols-2">
                    {pay_methods.map(method => {

                      let costs = 0;

                      if(event.customer_transaction_fee) {
                        costs = method.costs.fixed * 1.21;
                        costs += (method.costs.variable / 100 * total(true, false)) * 1.21;
                      }

                      if(costs){
                        costs = '(' + new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(costs) + ')';
                      } else {
                        costs = '';
                      }

                      return (<div key={method.name} className="flex">
                        <input type="radio" required onChange={setMethod} checked={state.method === method.name} name="method" id={'method_'+method.name} value={method.name} className="peer w-0 h-0 absolute opacity-0 pointer-events-none"/>
                        <label htmlFor={'method_'+method.name} className="h-15 p-2 border-2 w-full border-blue-600 dark:bg-gray-950 rounded-md flex items-center dark:text-white space-y-2 transition opacity-40 hover:opacity-100 peer-checked:opacity-100 text-sm cursor-pointer" style={{borderColor: theme.primary_color}}>
                          <img alt={method.label} className="w-[40px] sm:w-8 mr-3" src={method.image}/>
                          <span className="sm:text-center !m-0 !mr-1 block"><span className="font-bold">{t(method.label)}</span> {costs}</span>
                        </label>
                      </div>)

                    }) }
                  </div>
                </div>
              </div> : <div></div> }
              <div className="font-xl text-gray-900 text-right mt-4 md:mt-0 dark:text-gray-100 text-lg">
                {t('total')}: <span className="font-bold">{new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(total())}</span>
              </div>
            </div>

            <div className="bottom-nav p-4 flex justify-between items-center border-t bg-gray-50 dark:border-gray-700 dark:bg-gray-800">
              <div>
                <button type="button" onClick={goBack} className="back-btn text-sm text-gray-800 cursor-pointer dark:text-gray-200">{t('back')}</button>
              </div>
              <div className="flex items-center">
                <Timer/>
                <PrimaryButton theme={theme} loading={state.loading} label={t('place_order')}/>
              </div>
            </div>
          </div>
        </form>
      </animated.div>
  );

}

export default inject("DeviceStore")(observer(Checkout));
